export function subdivide<T>(size: number, array: T[]): T[][] {
  if (array.length <= size) return [array];
  return [array.slice(0, size), ...subdivide(size, array.slice(size))];
}

// export function toObjectByKey<T, K extends keyof T>(array: T[], key: K) {
//   const initialValue = {} as Record<K, T>;
//   return array.reduce((obj, item) => {
//     return {
//       ...obj,
//       [item[key]]: item,
//     };
//   }, initialValue);
// }

export function isEmpty<T>(arr: T[]) {
  return arr.length === 0;
}

export function areEqual<T>(arrA: T[], arrB: T[]) {
  const as = new Set(arrA);
  const bs = new Set(arrB);
  if (as.size !== bs.size) return false;
  for (const a of as) if (!bs.has(a)) return false;
  return true;
}

// Get keys
export function uniqueByKey<T>(array: T[], key: keyof T) {
  return [...new Set(array.map((item) => item[key]))];
}

// Get values
export function uniqueObjectsByKey<T>(arr: T[], key: keyof T) {
  return arr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[key]).indexOf(obj[key]) === pos;
  });
}

export function filterEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function groupBy<T, K>(f: (x: T) => K, xs: T[]): Map<K, T[]> {
  const m = new Map<K, T[]>();
  const l = xs.length;
  for (let i = 0; i < l; ++i) {
    const x = xs[i];
    const k = f(x);
    const g = m.get(k);
    if (g === undefined) {
      m.set(k, [x]);
    } else {
      g.push(x);
    }
  }
  return m;
}

export function intersperse<T>(sep: T, arr: T[]): T[] {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce((xs, x) => xs.concat([sep, x]), [arr[0]]);
}

export const intersperseWithIndex = <E, A = E>(x: (i: number) => E, a: A[]) =>
  ([] as Array<E | A>).concat(...a.map((e, i) => [x(i), e])).slice(1);

/**
 * [pass, fail]
 */
export function partition<T>(array: T[], isValid: (x: T) => boolean) {
  return array.reduce(([pass, fail], elem) => (isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]), [
    [],
    [],
  ] as T[][]);
}

export function intersect<T>(a: T[], b: T[]) {
  return a.filter((x) => b.includes(x));
}

function rotate<T>(input: T[], shift: number) {
  return [...input.slice(shift), ...input.slice(0, shift)];
}

export function traversable<T>(input: T[], validate: (x: T) => boolean): Array<T & { next?: T; prev?: T }> {
  return input.map((x, index) => {
    const listStartingWithIndex = rotate(
      input.filter((_, i) => i !== index),
      index
    );
    //.filter((y) => !eq(x, y));
    const next = listStartingWithIndex.find(validate);
    const prev = [...listStartingWithIndex].reverse().find(validate);
    return { ...x, prev, next };
  });
}
